import type { ComponentType } from "react";
import { IControlType, IDynamicForm } from "../../models/form/IDynamicForm";
import { SelectForm, TextForm, TitleForm } from "./items";

export const ComponentMap: Record<IControlType, ComponentType<IDynamicForm>> = {
    text: TextForm,
    email: TextForm,
    number: TextForm,
    "text-area": TextForm,
    password: TextForm,
    select: SelectForm,
    title: TitleForm,
};
