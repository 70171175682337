import { NotiProvider } from "@enerbit/base";
import LeftMenu from "../common/Menu/LeftMenu";
const SolarMonitoringPage = () => {
    return (
        <NotiProvider>
            <LeftMenu />
        </NotiProvider>
    );
};

export default SolarMonitoringPage;
