import { IDynamicForm } from "../../models/form/IDynamicForm";

export const IDs: IDynamicForm[] = [
    {
        name: "",
        label: "1. ID's",
        type: "title",
        sx: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: "ess_id",
        label: "ID del servicio de energía",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: "solar_id",
        label: "ID servicio energía solar",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: "measurement_point_id",
        label: "ID punto de medida",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 6, lg: 4 },
    },
];

export const solarInverterInformation: IDynamicForm[] = [
    {
        name: "",
        label: "2. Información del inversor",
        type: "title",
        sx: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: "integration_type",
        label: "Tipo de integración",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: "serial_number",
        label: "Número de serial",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: "brand",
        label: "Marca",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: "model",
        label: "Modelo",
        type: "text",
        props: { disabled: true },
        sx: { xs: 12, md: 3, lg: 3 },
    },
];

export const solarSystemInformation: IDynamicForm[] = [
    {
        name: "",
        label: "3. Información del sistema solar",
        type: "title",
        sx: { xs: 12, md: 6, lg: 4 },
    },
    {
        name: "capacity_kwp",
        label: "Capacidad solar instalada (kWp)",
        type: "text",
        sx: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: "solar_instalator",
        label: "Instalador solar",
        props: { disabled: true },
        type: "text",
        sx: { xs: 12, md: 6, lg: 6 },
    },
];

export const solarStateCommon: IDynamicForm[] = [
    {
        name: "address",
        label: "Dirección",
        type: "text",
        sx: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: "stratum",
        label: "Estrato",
        props: { disabled: true },
        type: "text",
        sx: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: "catastral_identifier_type",
        label: "Tipo de identificador catastral",
        props: { disabled: true },
        type: "text",
        sx: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: "catastral_identifier",
        label: "Identificador catastral",
        props: { disabled: true },
        type: "text",
        sx: { xs: 12, md: 6, lg: 6 },
    },
    {
        name: "longitude",
        label: "Longitud",
        type: "text",
        sx: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: "latitude",
        label: "Latitud",
        type: "text",
        sx: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: "altitude",
        label: "Altitud",
        type: "text",
        sx: { xs: 12, md: 3, lg: 3 },
    },
    {
        name: "plus_code",
        label: "Plus code",
        type: "text",
        sx: { xs: 12, md: 3, lg: 3 },
    },
];
