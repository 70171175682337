import { dayjs, enerbitColors } from "@enerbit/base";

interface ILabelText {
    period: string;
    start?: Date;
    value?: number;
}
interface IAvrLabelText {
    period: string;
    avgEnergySelfconsumption?: number;
    start?: Date;
    end?: Date;
}
export const getLabelText = ({ period, start, value = 0 }: ILabelText) => {
    if (!start) {
        return (
            <span
                style={{
                    color: enerbitColors.primary.main,
                    fontWeight: "bold",
                }}
            >
                Selecciona un valor
            </span>
        );
    }

    let dateFormatted = "";
    let connectionWord = "";
    let hourFormatted = "";

    switch (period) {
        case "hour":
            hourFormatted = dayjs(start).format("HH:mm");
            dateFormatted = dayjs(start).format("LL");
            connectionWord = "a las";
            break;

        case "day":
            hourFormatted = "";
            dateFormatted = dayjs(start).format("DD MMMM YYYY");
            connectionWord = "el";
            break;

        case "month_start":
            hourFormatted = "";
            dateFormatted = dayjs(start).format("DD MMMM YYYY");
            connectionWord = "el";
            break;

        default:
            break;
    }

    return (
        <span style={{ color: enerbitColors.primary.main }}>
            <span style={{ fontWeight: "bold" }}>
                {convertKWh(value).toFixed(1) + " "}kWh
            </span>
            <span>{` ${connectionWord} `}</span>
            {hourFormatted ? (
                <span style={{ fontWeight: "bold" }}>
                    {hourFormatted}
                    <br />{" "}
                </span>
            ) : (
                <br />
            )}
            <span style={{ fontWeight: "bold" }}>{`\n${dateFormatted}`}</span>
        </span>
    );
};

export const getAvgLabelText = ({
    period,
    avgEnergySelfconsumption = 0,
    start,
    end,
}: IAvrLabelText) => {
    let dateStartFormatted = "";
    let dateEndFormatted = "";
    let sinceWord = "";
    let untilWord = "";
    let hourEndFormatted = "";

    switch (period) {
        case "hour":
            dateStartFormatted = dayjs(start).format("HH:mm");
            hourEndFormatted = dayjs(end).format("HH:mm");
            dateEndFormatted = dayjs(end).format("LL");
            sinceWord = "de las";
            untilWord = "hasta las";
            break;

        case "day":
            dateStartFormatted = dayjs(start).format("DD MMMM YYYY");
            dateEndFormatted = dayjs(end).format("DD MMMM YYYY");
            sinceWord = "desde el";
            untilWord = "hasta el";
            break;

        case "month_start":
            dateStartFormatted = dayjs(start).format("DD MMMM YYYY");
            dateEndFormatted = dayjs(end)
                .subtract(1, "days")
                .format("DD MMMM YYYY");
            sinceWord = "desde";
            untilWord = "hasta";
            break;

        default:
            break;
    }

    if (!start) {
        return (
            <span
                style={{
                    color: enerbitColors.primary.main,
                    fontWeight: "bold",
                }}
            >
                Selecciona un valor
            </span>
        );
    }

    return (
        <span style={{ color: enerbitColors.primary.main }}>
            <span style={{ fontWeight: "bold" }}>
                {convertKWh(avgEnergySelfconsumption).toFixed(1) + " "}kWh{" "}
            </span>
            <span>
                {period == "hour" ? (
                    <span>
                        {sinceWord}{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {`${dateStartFormatted}\n`}
                        </span>{" "}
                        <br />
                    </span>
                ) : (
                    <span>
                        {sinceWord} <br />{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {`${dateStartFormatted}\n`}
                        </span>
                    </span>
                )}
            </span>
            {period == "hour" ? (
                <span>{` ${untilWord} `}</span>
            ) : (
                <span>
                    <br />
                    {untilWord}
                    <br />
                </span>
            )}
            {hourEndFormatted && (
                <span style={{ fontWeight: "bold" }}>
                    {`${hourEndFormatted}\n`} <br />
                </span>
            )}
            <span style={{ fontWeight: "bold" }}>{dateEndFormatted}</span>
        </span>
    );
};
export const convertKWh = (value: number) => {
    return value / 1000;
};
