import { initEnviroment } from "@enerbit/base";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
});

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_GEOPOLITICS_BASE_URL,
});

export const { bootstrap, mount, unmount } = lifecycles;
