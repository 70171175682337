import {
    AdapterDayjs,
    Box,
    Button,
    DateCalendar,
    EventOutlinedIcon,
    IconButton,
    InfoOutlinedIcon,
    LocalizationProvider,
    MonthCalendar,
    Popper,
    Tooltip,
    Typography,
    dayjs,
    enerbitColors,
} from "@enerbit/base";
import "dayjs/locale/es";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GraphicFiltersByTimestamp } from "../../const/Graphic";
import { useGraphicsStore } from "../../store/Graphics";
import { useSolarManager } from "../../store/SolarManager";
import {
    formatDateToText,
    getInitDay,
    getLastDayOfMonth,
    getNextDay,
} from "../../utils/Date";

export const SelectDatePickerOrRange = ({
    period,
    name,
    label,
}: {
    period: GraphicFiltersByTimestamp;
    name: string;
    label: string;
}) => {
    const [show, setShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { control, watch, setValue, reset } = useFormContext();

    const { getUsages } = useGraphicsStore();
    const { solarService } = useSolarManager();

    const handleShow = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (!show) reset();
        setShow(!show);
    };

    const handleDateRange = (
        type: "single" | "range" | "month",
        date: Date,
    ) => {
        const sinceDate = getInitDay(date);

        if (type === "single") {
            setValue("since", sinceDate);
            setValue("until", getNextDay(date));
        } else if (type === "range" || type === "month") {
            if (!watch("since") && !watch("until")) {
                setValue("since", sinceDate);
                setValue("until", null);
            } else if (!watch("until") && watch("since")) {
                setValue(
                    "until",
                    type === "range"
                        ? getNextDay(date)
                        : getLastDayOfMonth(date),
                );
            }
        }

        if (watch("since") && watch("until")) {
            getUsages({
                since: watch("since"),
                until: watch("until"),
                period_str: period,
                measurement_point_id: solarService?.measurement_point_id ?? "",
                parent_point_id: solarService?.parent_point_id ?? "",
            });
            setShow(false);
        }
    };

    const handleDateChange = (start: Date) => {
        handleDateRange("single", start);
    };

    const handleDateChangeRange = (date: Date) => {
        handleDateRange("range", date);
    };

    const handleMonthChangeRange = (date: Date) => {
        handleDateRange("month", date);
    };

    if (!name) return null;
    return (
        <Box>
            <Typography color="primary" mb={1}>
                {label}
            </Typography>
            <Controller
                name={name}
                control={control}
                render={() => (
                    <Box>
                        <Box sx={{ display: "flex" }}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={handleShow}
                                sx={{
                                    borderRadius: "10px",
                                    textTransform: "none",
                                }}
                                fullWidth
                            >
                                <Box
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <EventOutlinedIcon />
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            width: "100%",
                                            color: enerbitColors.primary.main,
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                        }}
                                    >
                                        {period ==
                                            GraphicFiltersByTimestamp.DAY ||
                                        period ==
                                            GraphicFiltersByTimestamp.MONTH
                                            ? `${formatDateToText(watch("since"), false)} - ${formatDateToText(
                                                  watch("until"),
                                                  true,
                                              )}`
                                            : formatDateToText(watch("since"))}
                                    </Typography>
                                </Box>
                            </Button>
                            <Tooltip title="Todas las fechas y horas están presentadas en la zona horaria: America/Bogotá">
                                <IconButton
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        ml: "4px",
                                    }}
                                >
                                    <InfoOutlinedIcon
                                        sx={{
                                            color: enerbitColors.primary.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Popper
                            open={show}
                            anchorEl={anchorEl}
                            placement="bottom"
                        >
                            <Box
                                p={2}
                                bgcolor="background.paper"
                                boxShadow={3}
                                borderRadius={2}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="es"
                                >
                                    {period ==
                                        GraphicFiltersByTimestamp.HOUR && (
                                        <DateCalendar
                                            maxDate={dayjs()}
                                            value={watch("since")}
                                            onChange={handleDateChange}
                                        />
                                    )}
                                    {period ==
                                        GraphicFiltersByTimestamp.DAY && (
                                        <DateCalendar
                                            maxDate={dayjs()}
                                            onChange={handleDateChangeRange}
                                        />
                                    )}
                                    {period ==
                                        GraphicFiltersByTimestamp.MONTH && (
                                        <MonthCalendar
                                            onChange={handleMonthChangeRange}
                                        />
                                    )}
                                </LocalizationProvider>
                            </Box>
                        </Popper>
                    </Box>
                )}
            />
        </Box>
    );
};
