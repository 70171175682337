import { api } from "@enerbit/base";
import { enqueueSnackbar } from "notistack";
import { create } from "zustand";
import { IInverter } from "../models/stores/Inverters";

interface Inverters {
    inverter: IInverter | null;
    getInverters: (id: string) => void;
}

const initialState = {
    inverter: null,
};

export const useInverters = create<Inverters>((set) => ({
    ...initialState,
    getInverters: async (id) => {
        try {
            const { data } = await api.get<IInverter>(
                `/solar-ops/inverter-info/${id}/`,
            );
            set({
                inverter: data,
            });
        } catch (error) {
            enqueueSnackbar("Ocurrió un error al consultar el inversor", {
                variant: "error",
            });
            throw error;
        }
    },
}));
