import {
    api,
    Country,
    EnerbitPagination,
    Estate,
    geopoliticsApi,
    State,
} from "@enerbit/base";
import { enqueueSnackbar } from "notistack";

export const getEstateByIdService = async (estateId: string) => {
    try {
        const { data } = await api.get<Estate>(
            `accounts/estates/${estateId}/`,
            {
                params: {
                    size: 300,
                },
            },
        );
        return data;
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al consultar el predio", {
            variant: "error",
        });
        throw error;
    }
};

export const getCountriesService = async () => {
    try {
        const { data } = await geopoliticsApi.get<EnerbitPagination<Country>>(
            `countries/`,
            {
                params: {
                    size: 300,
                },
            },
        );
        return (
            data.items.find((country) => country.name === "Colombia") ?? null
        );
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al consultar el pais", {
            variant: "error",
        });
        throw error;
    }
};

export const getStatesService = async (countryId: string) => {
    try {
        const { data } = await geopoliticsApi.get<EnerbitPagination<State>>(
            `/countries/${countryId}/states`,
            {
                params: {
                    size: 300,
                },
            },
        );
        return data.items;
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al consultar los departamentos", {
            variant: "error",
        });
        throw error;
    }
};

export const getCitiesService = async (stateId: string) => {
    try {
        const { data } = await geopoliticsApi.get<EnerbitPagination<State>>(
            `states/${stateId}/cities`,
            {
                params: {
                    size: 300,
                },
            },
        );
        return data.items;
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al consultar las ciudades", {
            variant: "error",
        });
        throw error;
    }
};
