import { Grid, Typography } from "@enerbit/base";
import type { FC } from "react";
import { IDynamicForm } from "../../../models/form/IDynamicForm";

export const TitleForm: FC<IDynamicForm> = ({ label }) => {
    return (
        <Grid item xs={12} sx={{ pt: "0px !important" }}>
            <Typography
                color="primary"
                sx={{ fontWeight: 700, fontSize: "16px", mb: "2px" }}
            >
                {label}
            </Typography>
        </Grid>
    );
};
