import { useEffect } from "react";
import { useSolarManager } from "../../../store/SolarManager";
import { mountedDisassembleAppServicesOrders } from "../../../utils/Spa.helper";

export const ServicesOrders = () => {
    const { solarService } = useSolarManager();

    useEffect(() => {
        if (solarService)
            mountedDisassembleAppServicesOrders(
                solarService.measurement_point_id,
            );
    }, []);

    return <div id="single-spa-application:app-services-orders" />;
};
