import {
    NotiProvider,
    PersonSearchOutlinedIcon,
    SpeakerNotesOutlinedIcon,
    ThemeConfig,
} from "@enerbit/base";
import { GenerationVariables } from "../common/Tabs/GenerationVariables/GenerationVariables";
import { InformationForm } from "../common/Tabs/Information/InformationForm";
import { ServicesOrders } from "../common/Tabs/ServicesOrders/ServicesOrders";
import { ISolarService } from "../models/solar-service/Solar";

export const getSolarDataService = (
    solarService: ISolarService,
    idEss: string,
) => {
    return [
        {
            label: "ID del servicio de energía asociada:",
            value: idEss,
            Icon: PersonSearchOutlinedIcon,
        },
        {
            label: "ID del servicio de energía solar:",
            value: solarService.solar_service_id,
            Icon: SpeakerNotesOutlinedIcon,
        },
        {
            label: "Tipo de integración:",
            value: solarService.integration_type,
            Icon: PersonSearchOutlinedIcon,
        },
        {
            label: "ID punto de medida:",
            value: solarService.measurement_point_id,
            Icon: PersonSearchOutlinedIcon,
        },
        {
            label: "Serial del inversor:",
            value: solarService.inverter_serial,
            Icon: PersonSearchOutlinedIcon,
        },
        {
            label: "Inverter ID:",
            value: solarService.inverter_id,
            Icon: PersonSearchOutlinedIcon,
        },
        {
            label: "Fecha inicio servicio",
            value: new Date(solarService.service_started_at)
                .toISOString()
                .slice(0, 10),
            Icon: PersonSearchOutlinedIcon,
        },
    ];
};

export const getSolarTabs = (idEss: string) => [
    {
        label: "Variables de generación",
        component: (
            <ThemeConfig>
                <NotiProvider>
                    <GenerationVariables />
                </NotiProvider>
            </ThemeConfig>
        ),
    },
    {
        label: "Información",
        component: (
            <ThemeConfig>
                <NotiProvider>
                    <InformationForm idEss={idEss} />
                </NotiProvider>
            </ThemeConfig>
        ),
    },
    {
        label: "Ordenes de servicio",
        component: <ServicesOrders />,
    },
];
