import { ITabModel } from "../tabs/Tab";

export interface IRootParams {
    id: string;
    titleText?: string;
    dataServiceInfo: {
        label: string;
        value: string;
        Icon: React.ElementType;
    }[];
    tabs: ITabModel[];
    statusCard: JSX.Element;
}
export interface IStatusCard {
    title: string;
    status: SmartbitStatus;
    isDisconnect: boolean;
    connectFunction: () => void;
    disconnectFunction: () => void;
}
export interface StatusCard {
    title: string;
    status: SmartbitStatus;
    isDisconnect: boolean;
    connectFunction: () => void;
    disconnectFunction: () => void;
}

export enum SmartbitStatus {
    Connected = "Connected",
    Disconnected = "Disconnected",
}
