import { ArrowDownwardIcon, enerbitColors } from "@enerbit/base";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Typography } from "@mui/material";
import { useGraphicsStore } from "../../../store/Graphics";
import { getAvgLabelText, getLabelText } from "../../../utils/Usages";

const InfoBox = ({
    icon: Icon,
    label,
}: { icon: React.ElementType; label: JSX.Element }) => (
    <Box display="flex" alignItems="center" sx={{ gap: "4px" }}>
        <Icon
            sx={{
                color: enerbitColors.secondary.orange.main,
                fontSize: "20px",
            }}
        />
        {label}
    </Box>
);

const DataBox = ({
    icon,
    label,
    value,
}: {
    icon: React.ElementType;
    label: JSX.Element;
    value: JSX.Element;
}) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
            mt: "10px",
            width: "100%",
            padding: "6px 16px",
            border: "1px solid #ddd",
            borderRadius: "10px",
        }}
    >
        <InfoBox icon={icon} label={label} />
        <Typography
            sx={{
                textAlign: "end",
            }}
        >
            {value}
        </Typography>
    </Box>
);

const GenerationCards = ({ period }: { period: string }) => {
    const { stadistic_energy_usages } = useGraphicsStore();
    return (
        <Box sx={{ mt: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
                <Box sx={{ width: "40%" }}>
                    <Box
                        sx={{
                            borderRadius: "10px 10px 0px 0px",
                            backgroundColor: enerbitColors.primary.main,
                            padding: "16px 10px",
                        }}
                    >
                        <Typography
                            sx={{ color: "white", textAlign: "center" }}
                        >
                            <span style={{ fontWeight: 700 }}>
                                Sobre la generación{" "}
                            </span>
                            <span>en el intervalo seleccionado:</span>
                        </Typography>
                    </Box>
                    {/* Bloques de datos generación */}
                    <DataBox
                        icon={ArrowUpwardIcon}
                        label={
                            <Box>
                                La generación
                                <br /> más alta
                            </Box>
                        }
                        value={getLabelText({
                            start: stadistic_energy_usages
                                ?.top_max_solar_active_energy_exported.start,
                            value: stadistic_energy_usages
                                ?.top_max_solar_active_energy_exported
                                .solar_active_energy_exported,
                            period,
                        })}
                    />
                    <DataBox
                        icon={ArrowDownwardIcon}
                        label={
                            <Box>
                                La generación
                                <br /> más baja
                            </Box>
                        }
                        value={getLabelText({
                            start: stadistic_energy_usages
                                ?.top_min_solar_active_energy_exported.start,
                            value: stadistic_energy_usages
                                ?.top_min_solar_active_energy_exported
                                .solar_active_energy_exported,
                            period,
                        })}
                    />
                    <DataBox
                        icon={AccessAlarmOutlinedIcon}
                        label={
                            <Box>
                                La generación
                                <br /> promedio
                            </Box>
                        }
                        value={getAvgLabelText({
                            period,
                            avgEnergySelfconsumption:
                                stadistic_energy_usages?.average_solar_active_energy_exported,
                            start: stadistic_energy_usages
                                ?.top_max_solar_active_energy_exported.start,
                            end: stadistic_energy_usages
                                ?.top_min_solar_active_energy_exported.end,
                        })}
                    />
                </Box>
                <Box sx={{ width: "40%" }}>
                    <Box
                        sx={{
                            borderRadius: "10px 10px 0px 0px",
                            backgroundColor: enerbitColors.primary.main,
                            padding: "16px 10px",
                        }}
                    >
                        <Typography
                            sx={{ color: "white", textAlign: "center" }}
                        >
                            <span style={{ fontWeight: 700 }}>
                                Sobre el consumo{" "}
                            </span>
                            <span>en el intervalo seleccionado:</span>
                        </Typography>
                    </Box>
                    {/* Bloques de datos consumo*/}
                    <DataBox
                        icon={ArrowUpwardIcon}
                        label={
                            <Box>
                                El consumo
                                <br /> más alto
                            </Box>
                        }
                        value={getLabelText({
                            start: stadistic_energy_usages
                                ?.top_max_conventional_active_energy_imported
                                .start,
                            value: stadistic_energy_usages
                                ?.top_max_conventional_active_energy_imported
                                .conventional_active_energy_imported,
                            period,
                        })}
                    />
                    <DataBox
                        icon={ArrowDownwardIcon}
                        label={
                            <Box>
                                El consumo
                                <br /> más bajo
                            </Box>
                        }
                        value={getLabelText({
                            start: stadistic_energy_usages
                                ?.top_min_conventional_active_energy_imported
                                .start,
                            value: stadistic_energy_usages
                                ?.top_min_conventional_active_energy_imported
                                .conventional_active_energy_imported,
                            period,
                        })}
                    />
                    <DataBox
                        icon={AccessAlarmOutlinedIcon}
                        label={
                            <Box>
                                El consumo
                                <br /> promedio
                            </Box>
                        }
                        value={getAvgLabelText({
                            period,
                            avgEnergySelfconsumption:
                                stadistic_energy_usages?.average_conv_active_energy_imported ??
                                0,
                            start: stadistic_energy_usages
                                ?.top_min_conventional_active_energy_imported
                                .start,
                            end: stadistic_energy_usages
                                ?.top_min_conventional_active_energy_imported
                                .end,
                        })}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default GenerationCards;
