import { dayjs } from "@enerbit/base";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
export const formatDateToText = (date: string, showYear: boolean = true) => {
    if (!date) return "No seleccionado";
    var showDate = new Date(date);
    return showDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: showYear ? "numeric" : undefined,
    });
};

export const getInitDay = (date: Date) => {
    const inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5
    return inputDate.startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const getLastDayOfMonth = (date: Date): string => {
    const inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5
    return dayjs(inputDate)
        .endOf("month")
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
};

export const getNextDay = (date: Date): string => {
    const now = dayjs().tz("America/Bogota"); // Hora actual en UTC-5
    const inputDate = dayjs(date).tz("America/Bogota"); // Fecha de entrada en UTC-5
    if (inputDate.isSame(now, "day")) {
        return now.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    } else {
        return inputDate.endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    }
};
