import * as singleSpa from "single-spa";
import { IRootParams } from "../models/root-params/RootParams";

export const mountedDisassembleAppLeftMenu = async (params: IRootParams) => {
    const appNamesPayments = singleSpa
        .getAppNames()
        .find((item) => item == "app-leftmenu");

    if (appNamesPayments) {
        singleSpa
            .unregisterApplication("app-leftmenu")
            .then(() => mountedAppSingleSpaLeftMenu(params));
    } else {
        mountedAppSingleSpaLeftMenu(params);
    }
};

export const mountedAppSingleSpaLeftMenu = (params: IRootParams) => {
    singleSpa.registerApplication(
        "app-leftmenu",
        (): Promise<any> =>
            System.import("@enerBit/leftmenu-service").catch(() =>
                console.log("error"),
            ),
        (location) => location.pathname.startsWith("/"),
        {
            ...params,
        },
    );
};

export const mountedDisassembleAppServicesOrders = async (
    measurementPointId: string,
) => {
    const appNamesPayments = singleSpa
        .getAppNames()
        .find((item) => item == "app-services-orders");

    if (appNamesPayments) {
        singleSpa
            .unregisterApplication("app-services-orders")
            .then(() => mountedAppSingleSpaServicesOrders(measurementPointId));
    } else {
        mountedAppSingleSpaServicesOrders(measurementPointId);
    }
};

export const mountedAppSingleSpaServicesOrders = (
    measurementPointId: string,
) => {
    singleSpa.registerApplication(
        "app-services-orders",
        (): Promise<any> =>
            System.import("@enerBit/services-orders").catch(() =>
                console.log("error"),
            ),
        (location) => location.pathname.startsWith("/"),
        {
            measurementPointId,
        },
    );
};
