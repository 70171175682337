import { api } from "@enerbit/base";
import { ISolarInformationForm } from "../models/form/SolarInformationForm";

export const updateState = async (
    stateId: string,
    estate: ISolarInformationForm,
) => {
    try {
        await api.patch(`/accounts/estates/${stateId}/`, {
            address: estate.address,
            state: estate.state,
            city: estate.city,
            geolocalization: {
                altitude: estate.altitude,
                longitude: estate.longitude,
                latitude: estate.latitude,
                plus_code: estate.plus_code,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const updateCapacity = async (
    solarServiceId: string,
    capacity: number,
) => {
    try {
        await api.patch(`/solar-ops/solar-details/${solarServiceId}/`, {
            capacity_peak: capacity,
        });
    } catch (error) {
        throw error;
    }
};
