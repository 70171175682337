import { Box, Grid, Typography } from "@enerbit/base";
import React from "react";
import { graphicFiltersByEnergy } from "../../../const/Graphic";
import { useGraphicsStore } from "../../../store/Graphics";

export const GraphicFilters = () => {
    const { filters, setFilters } = useGraphicsStore();
    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
                container
                spacing={2}
                sx={{ maxWidth: "75%", justifyContent: "flex-start", my: 2 }}
            >
                {graphicFiltersByEnergy.map((item, index) => {
                    const actualFilter =
                        filters[item.key as keyof typeof filters];
                    return (
                        <React.Fragment key={index}>
                            {item.isTitle ? (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography
                                        variant="body1"
                                        fontWeight="bold"
                                        sx={{
                                            color: item.lightColor,
                                            whiteSpace: "normal",
                                            wordBreak: "break-word",
                                            textAlign: "left",
                                        }}
                                    >
                                        {item.label}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box display="flex" flexDirection="column">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            sx={{
                                                cursor: "pointer",
                                                whiteSpace: "normal",
                                                wordBreak: "break-word",
                                            }}
                                            onClick={() =>
                                                setFilters({
                                                    ...filters,
                                                    [item.key]: !actualFilter,
                                                })
                                            }
                                        >
                                            <Box
                                                sx={{
                                                    width: 12,
                                                    height: 12,
                                                    borderRadius: "50%",
                                                    position: "relative",
                                                    mr: 1,
                                                    bgcolor: actualFilter
                                                        ? item.lightColor
                                                        : "#e6e6e6",
                                                    border: actualFilter
                                                        ? `2px solid ${item.darkColor}`
                                                        : "2px solid #e6e6e6",
                                                }}
                                            />
                                            <Typography
                                                variant="body2"
                                                color={
                                                    actualFilter
                                                        ? "text.primary"
                                                        : "#e6e6e6"
                                                }
                                                sx={{
                                                    whiteSpace: "normal",
                                                    wordBreak: "break-word",
                                                    textAlign: "left",
                                                }}
                                            >
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Box>
    );
};
