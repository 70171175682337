import { create } from "zustand";
import { IUsageParams, IUsageStore } from "../models/usages/Usages";
import { getUsagesService } from "../services/Usages";

const initialState = {
    isLoading: false,
    usages: [],
    stadistic_energy_usages: null,
    filters: {
        active_energy_selfconsumption: true,
        conventional_active_energy_imported: true,
        active_energy_selfconsumption_negative: true,
        conventional_active_energy_exported: true,
    },
};

export const useGraphicsStore = create<IUsageStore>()((set, get) => ({
    ...initialState,
    cleanUsages: () => {
        set(() => ({
            usages: [],
            stadistic_energy_usages: null,
        }));
    },
    getUsages: async (params: IUsageParams) => {
        try {
            get().cleanUsages();
            set(() => ({ isLoading: true }));
            const data = await getUsagesService(params);
            set({ ...data });
        } finally {
            set(() => ({ isLoading: false }));
        }
    },
    setFilters: (filters) => set({ filters }),
}));
