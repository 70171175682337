import { api } from "@enerbit/base";
import { enqueueSnackbar } from "notistack";
import { IUsageParams, IUsages } from "../models/usages/Usages";

export const getUsagesService = async (params: IUsageParams) => {
    try {
        const { data } = await api.get<IUsages>(
            `/solar-ops/usages/${params.measurement_point_id}/`,
            {
                params: {
                    start_date: params.since,
                    end_date: params.until,
                    period_str: params.period_str,
                    period_number: 1,
                    parent_point_id: params.parent_point_id,
                },
            },
        );
        return data;
    } catch (error) {
        enqueueSnackbar("Ocurrió un error al consultar los consumos", {
            variant: "error",
        });
        throw error;
    }
};
