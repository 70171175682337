import { Box, NavSidebar, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./assets/css/solarMonitoring.css";
import SolarMonitoringPage from "./pages/SolarMonitoringPage";

export default function Root() {
    return (
        <PrivateRoute>
            <ThemeConfig>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        overflow: "hidden",
                    }}
                >
                    <div
                        style={{
                            position: "fixed",
                            height: "100%",
                            zIndex: 10,
                        }}
                    >
                        <NavSidebar />
                    </div>
                    <Box className="Container-solar-monitoring">
                        <HashRouter>
                            <Routes>
                                <Route
                                    path="/solar-monitoring/attention/:idSolar/:idEss"
                                    element={<SolarMonitoringPage />}
                                />
                            </Routes>
                        </HashRouter>
                    </Box>
                </div>
            </ThemeConfig>
        </PrivateRoute>
    );
}
