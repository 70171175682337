import { z } from "zod";

export const SolarInformationSchema = z.object({
    capacity_kwp: z
        .string()
        .min(1, "La capacidad es requerida.")
        .regex(/^\d+(\.\d{1,3})?$/, {
            message: "Debe ser un número con hasta 3 decimales",
        })
        .transform((val) => parseFloat(val)) // Transforma a número
        .refine((val) => val >= 2 && val <= 100, {
            message: "El valor debe estar entre 2 y 100",
        }),
    state: z.string().min(1, "El departamento es requerido."),
    city: z.string().min(1, "La ciudad es requerida."),
    address: z.string().min(1, "La dirección es requerida."),
    longitude: z.string().min(1, "La longitud es requerida."),
    latitude: z.string().min(1, "La latitude es requerida."),
    altitude: z.string().min(1, "La altitud es requerida."),
    plus_code: z.string().min(1, "El plus code es requerida."),
});
