import { create } from "zustand";
import { IGeopolitics } from "../models/stores/Geopolitics";
import {
    getCitiesService,
    getCountriesService,
    getEstateByIdService,
    getStatesService,
} from "../services/Geolocalization";

const initialState = {
    estate: null,
    states: [],
    cities: [],
    country: null,
};

export const useGeopolitics = create<IGeopolitics>()((set) => ({
    ...initialState,
    getEstateById: async (estateId: string) => {
        const estate = await getEstateByIdService(estateId);
        set({ estate });
    },
    getCountries: async () => {
        const country = await getCountriesService();
        set({ country });
    },
    getStates: async (countryId: string) => {
        const states = await getStatesService(countryId);
        set({ states });
    },
    getCities: async (stateId: string) => {
        const cities = await getCitiesService(stateId);
        set({ cities });
    },
}));
