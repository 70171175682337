import {
    Box,
    Button,
    ErrorOutlineOutlinedIcon,
    FlashOnOutlinedIcon,
    WarningIcon,
    enerbitColors,
    theme,
} from "@enerbit/base";
import { SmartbitStatus } from "../../models/root-params/RootParams";
import { useSolarManager } from "../../store/SolarManager";
import TitleComponent from "./TitleComponent";

export const ConnectionCard = () => {
    const { solarService } = useSolarManager();

    !solarService && <Box />;

    return (
        <Box
            className="connection-status-card"
            sx={{
                border: `1px solid 
                ${
                    solarService!.smartbit_status === SmartbitStatus.Connected
                        ? enerbitColors.success.main
                        : enerbitColors.error.main
                }`,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <TitleComponent
                    color={theme.palette.primary.main}
                    IconTitle={
                        solarService!.smartbit_status ===
                        SmartbitStatus.Connected
                            ? ErrorOutlineOutlinedIcon
                            : WarningIcon
                    }
                    titleText="Estado del smartBit"
                    showButtonTop={false}
                />
            </Box>

            <>
                <Box
                    display="flex"
                    justifyContent="center"
                    gap="1rem"
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Button
                            sx={{
                                mt: 1,
                                textTransform: "capitalize",
                                "&.Mui-disabled": {
                                    background: `${solarService!.smartbit_status === SmartbitStatus.Connected ? enerbitColors.success[100]! : enerbitColors.error[100]!}`,
                                },
                            }}
                            size="small"
                            variant="contained"
                            disabled={true}
                            fullWidth
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                    color: `${solarService!.smartbit_status === SmartbitStatus.Connected ? enerbitColors?.secondary_green.main : enerbitColors.error.main}`,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FlashOnOutlinedIcon
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        marginRight: "0.3rem",
                                    }}
                                />{" "}
                                {solarService!.smartbit_status ===
                                SmartbitStatus.Disconnected
                                    ? "Desconectado"
                                    : "Conectado"}
                            </span>
                        </Button>
                    </Box>
                </Box>
            </>
        </Box>
    );
};
