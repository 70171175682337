import { api } from "@enerbit/base";
import { enqueueSnackbar } from "notistack";
import { create } from "zustand";
import { ISolarService } from "../models/solar-service/Solar";
import { ISolarManager } from "../models/stores/SolarManager";

const initialState = {
    solarService: null,
};

export const useSolarManager = create<ISolarManager>((set) => ({
    ...initialState,
    getSolarService: async (id) => {
        try {
            const { data } = await api.get<ISolarService>(
                `/solar-ops/solar-service-info/${id}/`,
            );
            set({
                solarService: data,
            });
        } catch (error) {
            enqueueSnackbar("Ocurrió un error al consultar el servicio solar", {
                variant: "error",
            });
            throw error;
        }
    },
}));
